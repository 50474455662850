import { leads } from './leads'
import { appraisals } from './appraisals'
import { inspections } from './inspections'
import { financialSupervisor } from '../external/finalcialSupervisor'
import { stock } from '../forwarder/stock'

const staff = {
  staff_leads: leads,
  staff_appraisals: appraisals,
  staff_inspections: inspections,
  staff_financings: financialSupervisor,
  staff_stock: stock,
}

export default staff
