
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import ExpandedItem from './ExpandedItem.vue'
  import { Details } from '@/utils/generalInterface'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'
  import details from '@/views/datatables/resources/details'

@Component({
  components: { ExpandedItem },
})
  export default class ExpandedRow extends Vue {
  @Prop({ type: String, default: '' }) readonly slug!: string;
  @Prop({
    type: Object,
    default: () => {
    },
  }) readonly item!: Details;

  get items () {
    const { item, slug } = this

    if (!details[slug]) throw new Error(`Unimplemented detail: ${slug}`)

    return details[slug].map((detail, i) => ({
      key: i,
      ...detail,
      content: getObjectValueByPath(item, detail.path),
    })).filter(({ content }) => content && content !== '')
  }
  }
